import React, { Platform } from "react";

import { Box, Heading, Image } from "grommet";
import {
  isIOS,
  isAndroid
} from "react-device-detect";

import queryString from 'query-string';
import {ApiCallNoAuth} from "../components";

const AppStore = ({center, redirect, tid, ...props}) => {
    const ppurl = 'https://apps.apple.com/us/app/painpoint-journal/id1544669772';

    redirect(ppurl, false);

    const _postAndRedirect = () => {
        redirect(ppurl, true);
    };

    return (
        <Box height='10em' flex={false} margin={center ? { horizontal: 'auto' } : { horizontal: '4em' }}>
            <a href='#' onClick={_postAndRedirect}>
                <img alt='Get it on Apple App Store' height='60px' src='app-store.png'/>
            </a>
        </Box>
    );
}

const PlayStore = ({center, redirect, tid, ...props}) => {
    const ppurl = 'https://play.google.com/store/apps/details?id=com.painpointanalytics.painpointmobile';

    redirect(ppurl, false);

    const _postAndRedirect = () => {
        redirect(ppurl, true);
    };

    return (
        <Box height='10em' flex={false} margin={center ? {horizontal: 'auto'} : {horizontal: '4em'}}>
            <a href='#' onClick={_postAndRedirect}>
                <img alt='Get it on Google Play' height='60px' src='play-store.png'/>
            </a>
        </Box>
    );
}

const Pitch = () => (
    <Box width='25em' height='7em' flex={false} margin={{ horizontal: '3em', bottom: '1em' }}>
        Get the first mobile pain tracking and communications system to track your pain and
        improve your care provider's ability to accurately assess your recovery process!
    </Box>
);

const Icons = (props) => {
    const goog = isAndroid;
    const appl = isIOS;
    const none = !(goog || appl);

    React.useEffect(()=> {
        if (!!props.tid)
            ApiCallNoAuth('post', `/trackers/${props.tid}/public`, {
                download: true,
                platform: `${goog ? "android" : appl ? "ios" : "other"} (${window.navigator.platform})`
            });
    }, [goog, appl, props.tid]);

    const _redirect = (url, force) => {
        if (!props.redirect && !force) return;

        if (!!props.tid) {
            ApiCallNoAuth('post', `/trackers/${props.tid}/public`, {
                download: true,
                appstore: true
            }).then(() => {
            }).catch(err => {
            })
            .finally(() => {
                window.location = url;
            });
        } else {
            window.location = url;
        }
    }

    return (<Box margin={{top: '1em'}}>
        {
            goog &&
            <PlayStore {...props} center={true} redirect={_redirect}/>
        }
        {
            appl &&
            <AppStore {...props} center={true} redirect={_redirect}/>
        }
        {
            none &&
            <Box direction='row-responsive'>
                <PlayStore embedded={props.embedded} redirect={_redirect}/>
                <AppStore embedded={props.embedded} redirect={_redirect}/>
            </Box>
        }
    </Box>);
}

const HeaderLogo = ({embedded}) => (
    <Box width='10em' height='2.5em' margin={embedded && {left: '1.5em', top: '1.5em', right: '2em', bottom: '1em'}}>
        <Image src='/painpoint-patient-logo.png' fit='contain'/>
    </Box>
);

export const Download = ({ embedded, ...props }) => {
    const values = props.location ? queryString.parse(props.location.search) : {};
    const redirect = ( values.redirect === "true" );
    const tid = ( values.tid );

    return (
        <Box fill align="center"
             pad={{top: "large", horizontal: "small"}}
             margin={embedded && { top: '4em' }}>
            { !embedded &&
                <>
                    <Heading textAlign="center" level="2" margin={{bottom: '1em'}}>
                        <HeaderLogo embedded={embedded}/>
                    </Heading>
                    <Pitch/>
                    <Icons redirect={redirect} tid={tid}/>
                </>
            }
            { embedded &&
                <Box direction='row-responsive'>
                    <HeaderLogo embedded={embedded}/>
                    <Icons redirect={redirect} tid={tid} embedded={embedded}/>
                </Box>
            }
        </Box>
    );
}
